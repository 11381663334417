<template>
    <div class="kt-form__control">
        <input
            @click="getTimePicker()"
            v-model="timeSelected"
            class="form-control"
            :class="isInvalid ? 'is-invalid' : ''"
            v-validate="isNeedValidation ? 'required' : ''"
            :name="name"
            :id="timeId"
            :placeholder="placeHolder"
            autocomplete="off"
        />
        <small
            v-show="errors.has(name)"
            class="help is-danger"
            style="color: red;"
            >{{ errors.first(name) }}</small
        >
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: "Jam",
            timeSelected: null
        };
    },
    props: {
        placeHolder: { type: String },
        timeId: {},
        value: { type: String },
        isNeedValidation: { type: Boolean, default: true },
        isInvalid: {type: Boolean, default: false}
    },
    watch: {
        value: function(val) {
            this.timeSelected = val;
        }
    },
    methods: {
        getTimePicker: function() {
            $("#" + this.timeId)
                .timepicker({
                    minuteStep: 1,
                    showMeridian: false,
                    snapToStep: true,
                    explicitMode: true
                })
                .on("change", () => {
                    this.timeSelected = $("#" + this.timeId).val();
                    this.$emit("update:changedValue", this.timeSelected);
                });
        }
    },
    mounted() {
        this.getTimePicker();
    }
};
</script>
